
class WS {
    socket = null;
    url = "";
    isConnected = false;
    stateChangeListeners = [];
    messageListeners = [];
    reconnectOnClose = true;

    constructor() {

        if (window.location.protocol === "https:") {
            this.url = "wss://"+window.location.hostname+"/ws";
        } else {
            this.url = "ws://"+window.location.hostname+":8081/ws";
        }

        this.start(this);
    }

    start(th) {
        if (this.socket) {
            this.socket.close();
        }

        console.log("WS connect to:", th.url);

        th.socket = new WebSocket(th.url);
        th.socket.onopen = () => {
            console.log('ws open');

            if (window.token !== "") {
                window.ws.send({t:"token", v: window.token });
            }

            th.isConnected = true;
            th.stateChangeListeners.forEach(fn => fn(true));
        }
        th.socket.onerror = (e) => console.error(e);
        th.socket.onclose = () => {
            th.isConnected = false;
            th.stateChangeListeners.forEach(fn => fn(false));

            if (!th.reconnectOnClose) {
                console.log('ws closed by app');
                return;
            }

            setTimeout(th.start(th), 3000);
        }
        th.socket.onmessage = (event) => {            
            //console.log(JSON.parse(event.data));
            th.messageListeners.forEach(fn => fn(event.data));
        }
    }

    close() {
        if (this.socket == null) {
            return
        }
        this.reconnectOnClose = false;

        this.socket.close();
        this.socket = null;
    }

    on(fn) {
        this.messageListeners.push(fn);
    }

    off(fn) {
        this.messageListeners = this.messageListeners.filter(l => l !== fn);
    }

    onStateChange(fn) {
        this.stateChangeListeners.push(fn);
        return () => {
          this.stateChangeListeners = this.stateChangeListeners.filter(l => l !== fn);
        };
    }

    send(data){
        this.socket.send( JSON.stringify(data) );
    }
}

export default WS;