import './App.css';
import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import SesContext from './Context';

const Reconnect = lazy(() => import( './components/Reconnect/Reconnect'));
const TopPanel = lazy(() => import( './components/TopPanel/topPanel'));
const LoginPage = lazy(() => import( './components/LoginPage/index'));
const MainPage = lazy(() => import( './components/MainPage/index'));
const BoxPage = lazy(() => import( './components/BoxPage/index'));
const ArrowPage = lazy(() => import( './components/ArrowPage/index'));
const BoxSettingPage = lazy(() => import( './components/BoxSettingPage/index'));
const BoxThresholdPage = lazy(() => import( './components/BoxThresholdPage/index'));
const Console = lazy(() => import( './components/Journal/journal'));
const GrapshPage = lazy(() => import( './components/Grapsh/index'));

function App() {

  let navigate = useNavigate();
  const [Offline, setOffline] = useState();
  const [Ses, SetSes] = useState({User: "", Grp: ""});
  const [Ver, SetVer] = useState({Release: "", Commit: "", BuildTime: ""});

  useEffect(() => {
    window.ws.on( WSMsg.bind(this) );

    if (window.ws.isConnected) {
      window.ws.send({t:"GetVer"});
    }

    window.ws.onStateChange((online) => {
      console.log("WS connect:", online);

      setOffline(!online);

      if (online) {
          window.ws.send({t:"GetVer"});
      }
    });

    setOffline(!window.ws.isConnected)

    return function Unmount() {
      window.ws.off(WSMsg);
    }
  }, []);

  let WSMsg = (data) => {

    var obj = JSON.parse(data);    

    switch (obj.t) {
    case "NideLogin":        
        //console.debug("NideLogin:", obj);                
        navigate("/login");
        break;

    case "token":

        if (!obj.a || window.token == null) {
          window.token = obj.v;
          localStorage.setItem("token", obj.v);
        }
        
        SetSes({User: obj.u, Grp: obj.g});

        navigate("/");
        break;

    case "Ver":
      SetVer(obj);
      break;

    default:
      // console.log("WS recive:", data);
    }
  }

  return (
    <div className="App">

      <SesContext.Provider value={{Ses, SetSes}}> 
        <Suspense fallback={<div>Loading...</div>}>
          <TopPanel />
          <Routes>      
            <Route path="/" element={<MainPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/box/:id" element={<BoxPage />} />
            <Route path="/arrow/:id" element={<ArrowPage />} />
            <Route path="/boxOptions/:id" element={<BoxSettingPage />} />
            <Route path="/boxThreshold/:id" element={<BoxThresholdPage />} />
            <Route path="/journal" element={<Console />} />
            <Route path="/grapsh/:trg/:id/:val" element={<GrapshPage />} />
            <Route path="/grapsh/:trg/:id/:val/:aid/:cid" element={<GrapshPage />} />
          </Routes>
        </Suspense>
      </SesContext.Provider>

      <Reconnect show={Offline}>
        Соединение с ПКО...
      </Reconnect>

      ГК "МЕТТЭМ ТРАНСПОРТ" © Все права защищены. {Ver.Commit}
    </div>
  );

}

export default App;
